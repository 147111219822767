import { mapState, mapGetters, mapActions } from 'vuex';

// Auth
export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
  }),
  ...mapGetters('auth', ['loggedIn']),
};

export const authMethods = mapActions('auth', ['logIn', 'logOut', 'register', 'resetPassword']);

// Personnel
export const personnelComputed = {
  ...mapGetters('personnel', [
    'getLoadingPersonnelStatus',
    'getClientPersonnel',
  ]),
};

export const personnelMethods = mapActions(
  'personnel',
  [
    'createPersonnel',
    'loadClientPersonnel',
    'setSelectedPersonnel',
    'loadOffsetClientPersonnel',
  ],
);

// Incidents
export const incidentsComputed = {
  ...mapGetters('incidents', [
    'getCurrentIncident',
    'getActiveIncidents',
    'getArchivedIncidents',
    'getPreplanIncidents',
    'getActiveIncidentsPerPeriod',
    'getArchivedIncidentsPerPeriod',
    'getPreplanIncidentsPerPeriod',
    'getCurrentIncident']),
};

export const incidentsMethods = mapActions(
  'incidents',
  [
    'loadIncident',
    'updateIncident',
    'loadActiveIncidents',
    'loadArchivedIncidents',
    'loadPreplanIncidents',
    'setActiveIncidents',
    'setArchivedIncidents',
    'setPreplanIncidents',
    'setCurrentIncident',
    'updateCurrentIncident',
    'cleanCurrentIncident',
    'launchNewIncidentFromData',
    'launchNewIncidentFromPreplan',
    'archiveIncident',
  ],
);

// Checklists
export const checklistsComputed = {
  ...mapGetters('checklists', [
    'getClientChecklists',
    'getClientChecklistItems',
    'getCurrentIncidentChecklists',
    'getNewIncidentChecklists',
  ]),
};

export const checklistsMethods = mapActions(
  'checklists',
  [
    'loadClientChecklists',
    'updateIncidentChecklists',
    'saveCurrentIncidentChecklists',
    'saveNewIncidentChecklists',
  ],
);

// Files
export const filesComputed = {
  ...mapGetters('files', [
    'getIncidentFiles',
  ]),
};

export const filesMethods = mapActions('files', [
  'loadIncidentFiles',
  'getIncidentFileUrl',
  'downloadIncidentFile',
  'uploadIncidentFile',
  'updateIncidentFiles',
  'editNameIncidentFile',
  'deleteIncidentFile',
  'setIncidentFiles',
  'toggleSharedPortalIncidentFile',
  'cleanStateFiles',
]);

// Notes
export const notesComputed = {
  ...mapGetters('notes', ['getIncidentNotes']),
};

export const notesMethods = mapActions('notes', [
  'loadIncidentNotes',
]);

// Tags
export const tagsComputed = {
  ...mapGetters('tags', [
    'getClientTags',
    'getIncidentCurrentTags',
    'getIncidentNewTags',
  ]),
};

export const tagsMethods = mapActions(
  'tags',
  [
    'loadClientTags',
    'updateIncidentTags',
    'setIncidentCurrentTags',
    'setIncidentNewTags',
  ],
);

// Tasks
export const tasksComputed = {
  ...mapGetters('tasks', [
    'getCurrentTask',
    'getListenMapLocationStatus',
    'getTasks',
    'getIncidentTasks',
    'getLoadingTasksStatus',
    'getTasksPaginated',
    'getTasksCurrentPage',
    'getTasksTotalPages',
  ]),
};

export const tasksMethods = mapActions(
  'tasks',
  [
    'updateSearch',
    'switchClickLocationStatus',
    'loadOffsetTasks',
    'loadTasks',
    'pageControl',
    'setTasksCurrentIncident',
    'setCurrentTask',
    'createTask',
  ],
);

// Task Types
export const taskTypesComputed = {
  ...mapGetters('taskTypes', [
    'getTaskTypes',
    'getLoadingTaskTypeStatus',
  ]),
};

export const taskTypesMethods = mapActions(
  'taskTypes',
  [
    'loadOffsetTaskTypes',
    'createTaskType',
    'loadTaskTypes',
  ],
);

// Units
export const unitsComputed = {
  ...mapGetters('units', [
    'getClientUnits',
    'getLoadingUnitsStatus',
    'getClientStaggedUnits',
    'getClientRespondingUnits',
    'getClientAvailableUnits',
  ]),
};

export const unitsMethods = mapActions('units', [
  'setActiveUnits', 'clearUnitsSelection',
  'updateSearch', 'setSelectedUnit',
  'loadOffsetClientUnits', 'loadClientUnits',
  'updateUnitsStatus', 'setUnitFilter', 'createUnit',
]);

// Layers
export const layersComputed = {
  ...mapGetters('layers', ['getLayers', 'getCurrentLayer']),
};

export const layersMethods = mapActions('layers', ['loadLayers', 'changeCurrentLayer']);

// Map Tools
export const mapToolsComputed = {
  ...mapGetters('mapTools', [
    'getCurrentMapTools',
    'getEditingMapTools',
    'getEditingMapToolsData',
  ]),
};

export const mapToolsMethods = mapActions(
  'mapTools',
  [
    'createMapTool',
    'loadMapTools',
    'filterMapTools',
    'setEditingMapTools',
    'disableEditingTool',
    'updateMapToolsRadius',
    'deleteMapTools',
  ],
);

// Landmarks
export const landmarksComputed = {
  ...mapGetters('landmarks', [
    'getCurrentLandmarks',
  ]),
};

export const landmarksMethods = mapActions(
  'landmarks',
  [
    'createLandmark',
    'loadLandmarks',
    'deleteLandmark',
  ],
);

// Layout
export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    loader: (state) => state.loader,
  }),
};

export const layoutMethods = mapActions('layout', [
  'changeLayoutType',
  'changeLayoutWidth',
  'changeLeftSidebarType',
  'changeTopbar',
  'changeLoaderValue']);

// Notification
export const notificationMethods = mapActions('notification', ['success', 'error', 'clear']);
